import React, { Component } from 'react'


export default class AR extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }


  render() {
    return (<>
      <p>TODO AR stuff</p>
    </>)
  }
}

